import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from 'styled-components';
import { deviceLessThan, deviceGreaterThan } from '../../../styles/breakpoints';

const Dots = styled.ul`
  position: relative;
  bottom: 0px;
  left: -0.85%;
  @media ${deviceLessThan.laptopS} {
    left:0px;
  }
  &&& li  {
    margin: 0 auto;
    & button:before {
      font-size: 12px;
      color: ${p => p.theme.colors.TRANSPARENT};
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 2px solid ${p => p.dotColor ? p.theme.colors[p.dotColor] : `${p.theme.colors.WHITE}`};
      line-height: 13px;
      opacity: 1;
    }
    & button:focus:before {
      opacity: 1;
    }
  }
  &&& li.slick-active  {
    & button:before {
      font-size: 12px;
      opacity: 1;
      background-color: ${p => p.dotColor ? p.theme.colors[p.dotColor] : `${p.theme.colors.WHITE}`};
    }
  }
`;


const CustomSlider = styled(Slider)`
    *,*:focus,*:hover{
        outline:none;
    }

    & .slick-track {
        display: flex;
        align-items:  ${props => props.alignItems ? props.alignItems : 'center'};
        justify-content: ${props => props.jContent};
        //initial load width:0 issue fix
        @media ${deviceGreaterThan.tablet}{
             width: max-content !important;
         }
         @media ${deviceLessThan.tablet}{
             width: ${props => props.sTrackWidth};
         }
    }

    & .slick-slide {
        transform: ${props => props.centerMode ? 'scale(0.9)' : 'scale(1)'};
        transition: all 0.2s ease;
        will-change: transform,opacity;
        width: ${props => props.width};
        padding: ${props => props.padding};

        @media ${deviceLessThan.laptop} {
            width: ${props => props.mWidth};
        }

        @media ${deviceLessThan.tablet} {
            width: ${props => props.sWidth};
        }
    }

    & .slick-center {
        opacity: 1;
        transform: scale(1);
    }
`;

const CarouselContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  position: relative;
  @media ${deviceLessThan.tablet} {
        width: 100%;
    }
`;

function NextArrow(props) {
    const {
        className,
        style,
        onClick
    } = props;
    return (
        <div></div>
        // <Icon
        //   type="right"
        //   className={className}
        //   onClick={onClick}
        //   style={{
        //     ...style,
        //     display: 'block',
        //     color: 'black',
        //     fontSize: '15px',
        //     right: -15,
        //   }}
        // />
    );
}

function PreviousArrow(props) {
    const {
        className,
        style,
        onClick
    } = props;
    return (
        // <Icon
        //   type="left"
        //   className={className}
        //   onClick={onClick}
        //   style={{
        //     ...style,
        //     display: 'block',
        //     color: 'black',
        //     fontSize: '15px',
        //     left: -15,
        //   }}
        // />
        <div></div>
    );
}

function appendDots(dotColor, dots) {
    return (
        <Dots dotColor={dotColor}>
            {dots}
        </Dots>
    );
}

class CustomCarousel extends React.PureComponent {
    constructor() {
        super();
    }

    render() {
        const {
            className = 'carousel-container',
            width,
            padding,
            sWidth,
            alignItems,
            centerMode = true,
            variableWidth = true,
            dotColor,
            initialSlide = 2,
            jContent,
            sTrackWidth,
            autoplay = false,
            speed = 300,
            autoplaySpeed = 0,
            cssEase = 'linear',
            mWidth,
        } = this.props;

        let settings = {
            infinite: true,
            className: className,
            centerMode: centerMode,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            focusOnSelect: true,
            initialSlide: initialSlide,
            adaptiveHeight: false,
            variableWidth: variableWidth,
            centerPadding: "0vw",
            focusOnSelect: true,
            autoplay: autoplay,
            speed: speed,
            autoplaySpeed: autoplaySpeed,
            cssEase: cssEase,
            nextArrow: <NextArrow />,
            prevArrow: <PreviousArrow />,
            appendDots: appendDots.bind(null, dotColor)
        };
        if (this.props.settings) {
            settings = {
                ...settings,
                ...this.props.settings
            };
        }
        return (
            <CarouselContainer>
                <CustomSlider
                    {...settings}
                    width={width}
                    padding={padding}
                    sWidth={sWidth}
                    sTrackWidth={sTrackWidth}
                    alignItems={alignItems}
                    dotColor={dotColor}
                    jContent={jContent}
                    mWidth={mWidth}
                >{this.props.children}</CustomSlider>
            </CarouselContainer>
        );
    }
}

export default CustomCarousel;
